import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/layout/layout";

// markup
const IndexPage = () => {
  return (
    <Layout title="Lovely Ladyverse">
      <h1 class="flex-pageheading">Welcome to Lovely Ladyverse dot com!</h1>
      <StaticImage src="../images/bluehills21.png" alt="Bluehills Country '21"/>
      <p>"Home of the ladies"</p>
    </Layout>
  );
};

export default IndexPage;
